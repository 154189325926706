<template>
  <div>
    <MainFrame offset="-7.5vh">
      <List>
        <ListItem to="/accountJudgment/0/0/0">
          <template #left>
            <img src="../assets/personal.png" />
          </template>
          <template #right>
            <h4>个人账户</h4>
            <p>个人信息管理，就诊方便无忧</p>
          </template>
        </ListItem>

        <ListItem to="/check/0">
          <template #left>
            <img src="../assets/bill.png" />
          </template>
          <template #right>
            <h4>我的账单</h4>
            <p>充值消费清单，账目清晰明了</p>
          </template>
        </ListItem>

        <ListItem to="/address/0/0">
          <template #left>
            <img src="../assets/location.png" />
          </template>
          <template #right>
            <h4>地址管理</h4>
            <p>收货地址管理，快递精准派送</p>
          </template>
        </ListItem>
      </List>
    </MainFrame>
  </div>
</template>

<script>
    // @ is an alias to /src
    import MainFrame from '@/components/home-page/MainFrame.vue';
    import List from '@/components/home-page/List.vue';
    import ListItem from '@/components/home-page/ListItem.vue';

    export default {
        name: 'Self',
        components: {
            MainFrame,
            List,
            ListItem
        },
        beforeCreate(){
            this.$store.dispatch('pageData/setActiveTab', 'self');
        }
    }
</script>

<style scoped="" lang="less">
  @import "../style/var.less";

  .left img{
    margin-left: 10px;
    width: 40px;
  }
  .right{
    h4{
      margin: 15px 0 5px;
    }
    p{
      font-size: @font-size-md;
      margin: 5px 0 15px;
    }
  }
</style>
